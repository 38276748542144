.modal-about-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    z-index: 100;
    overflow: hidden;
    display: flex;
    justify-content: end;

    &.show-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
    }

    .modal-content {
        @include bg-pattern;
        padding: 20px;
        height: 100vh;
        width: 75%;
        backdrop-filter: blur(10px);
        transform: translateX(100%);
        transition: transform 0.5s ease;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &.show-content {
            transform: translate(0);
        }

        &.closing {
            transform: translateX(100%);
        }

        .modal-button_block {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .modal-close {
                border-radius: 50%;
                display: flex;
                text-align: right;
                cursor: pointer;
            }
        }

        .about-content {
            margin: 0 100px 100px 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            h2 {
                font-size: 80px;
            }

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .block-img {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: start;

                    img {
                        height: 450px;
                    }
                }

                p {
                    font-size: 16px;
                    text-align: center;
                }
            }

            .carousel-dots {
                text-align: center;
                margin-top: 50px;

                .dot {
                    height: 15px;
                    width: 15px;
                    margin: 0 5px;
                    background-color: #FFF;
                    border-radius: 50%;
                    display: inline-block;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &.active {
                        background-color: #000;
                    }
                }
            }
        }
    }
}