header {
  display: flex;
  padding: 25px 50px;
  height: 128px;

  .home-nav {
    width: 100%;

    nav {
      display: flex;
      justify-content: space-between;
      padding: 25px 250px;
      width: 100%;

      .accueil {
        font-weight: 900;
        font-size: 13px;
        margin-inline-start: 0;
        margin-top: 10px;
      }

      ul {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        gap: 20px;

        li {
          display: flex;

          a {
            display: flex;
            font-size: 13px;
            font-weight: 700;
            color: black;
            transition: transform 0.3s ease;
          }
        }
      }

    }
  }


  @media only screen and (max-width: $ms) {
    padding: 50px 0;

    nav {
      display: flex;
      justify-content: center;
      padding: 0;

      .accueil {
        margin-top: 0;
      }
    }
  }
}

.arrow-top-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background-color: inherit;
  color: $bg-color;
  border: none;
  padding: 10px 15px;

  svg {
    width: 42px;
    height: 42px;
    transition: opacity 0.3s ease;
    cursor: pointer;
    background-color: #000;
    border-radius: 50%;

    &:hover {
      opacity: 0.7;
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  h2 {
    font-size: 250px;
    text-align: center;

    @media only screen and (max-width: $ms) {
      font-size: 150px;
      margin-top: 50px;
    }

    @media only screen and (max-width: $ss) {
      font-size: 100px;
    }
  }

  .end-of {
    font-size: 250px;
    text-align: center;
    margin-top: -150px;

    @media only screen and (max-width: $ms) {
      font-size: 150px;
      margin-top: -100px;
    }

    @media only screen and (max-width: $ss) {
      font-size: 100px;
      margin-top: -65px;
    }
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;

    h4 {
      margin-bottom: 20px;
      font-size: 25px;
      opacity: 0;
      animation: slide-in-from-bottom 1s forwards;
      animation-delay: 0.2s;

      @media only screen and (max-width: $ms) {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    p,
    a {
      margin-bottom: 20px;
      font-size: 25px;
      opacity: 0;
      animation: slide-in-from-bottom 1s forwards;
      animation-delay: 0.2s;

      @media only screen and (max-width: $ms) {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    a {
      font-weight: 700;
    }

    .insta {
      font-weight: 500;
    }

    .mention-footer {
      display: flex;
      text-align: center;
      width: 100%;
      justify-content: space-evenly;

      @media only screen and (max-width: $ss) {
        flex-direction: column;
      }
    }
  }
}

@keyframes slide-in-from-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}