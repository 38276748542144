.conteneur-jprotectcar {
    margin: 100px 30px;
    display: flex;
    flex-direction: column;

    .hero {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1380px;
        height: calc(100vh - 128px);

        h2 {
            font-size: 100px;
            text-align: center;
            padding-right: 225px;
            width: calc(100% - 225px);
            cursor: default;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -30px;

            .cover {
                display: flex;
                flex-direction: column;
                align-items: end;

                img {
                    height: 525px;
                    object-fit: contain;
                }

                p {
                    font-style: italic;
                    font-weight: 700;
                }
            }
        }

    }

    .profil {
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .block-text {
            padding-inline: 16px;
            font-style: italic;
            font-size: 18px;
            width: 250px;
            height: 50vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .block-video {
            height: 50vh;
            width: 265px;

            video {
                border: 2px solid black;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .carousel {
        display: flex;
        height: 100vh;
        justify-content: center;
        gap: 170px;

        .title {
            margin-left: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            .date {
                font-style: italic;
                position: absolute;
                top: 100px;
                left: 0;
                font-size: 16px;
            }

            h3 {
                font-size: 100px;
            }

            .district {
                margin-top: -60px;
            }
        }

        .slider {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 500px;
            padding-block: 35px;

            img,
            video {
                height: 100%;
                width: 100%;
                border: 5px solid #000;
                border-radius: 60px;
                background-color: #000;
            }

            img {
                object-fit: cover;
            }

            .arrow {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding-inline: 5px;

                svg {
                    color: #FFF;
                    cursor: pointer;
                }
            }
        }
    }

    .real {
        height: 100vh;
        width: 100%;
        margin-top: 200px;

        .real-title {
            width: 100%;
            margin-top: 50px;
            text-align: right;

            h2 {
                font-size: 50px;
            }

            h3 {
                font-size: 50px;
                margin-top: -25px;
            }
        }

        .real-block-video {
            display: flex;
            justify-content: center;
            align-items: center;
            align-items: center;
            height: calc(100vh - 165px);
            padding-bottom: 150px;
            margin-top: -22px;

            .block-video {
                height: 100%;
                position: relative;

                .date {
                    position: absolute;
                    bottom: 0;
                    left: -27px;
                    writing-mode: vertical-rl;
                    transform: rotate(180deg);

                    p {
                        font-size: 20px;
                    }
                }

                video {
                    border: 2px solid black;
                    height: 100%;
                    filter: grayscale(100%);
                    object-fit: contain;
                }
            }
        }
    }

    .covering {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            width: 100%;

            h2 {
                font-size: 40px;
            }
        }


        .block-img {
            display: flex;
            gap: 20px;
            width: 100%;
            height: 70vh;

            .block-small-img {
                display: flex;
                flex-direction: column;
                gap: 20px;
                height: 100%;

                .small-img {
                    height: calc(50% - 10px);   
                }
            }

            .large-img {
                height: 100%;
            }

            img {
                object-fit: contain;
                height: 100%;
            }
        }

        .desc {
            width: 100%;

            p {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                font-style: italic;
                font-size: 20px;
            }
        }
    }

}