$bg-color: #C9C9BE;
/* Taille d'écran */
$vs: 320px;
$ss: 425px;
$ms: 768px;
$ls: 1024px;
$bs: 1440px;
$big: 1880px;


@mixin bg-pattern {
  background-color: $bg-color;
  background-image: url("/public/assets/background.webp");
  // background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.09) 1px, transparent 1px),
  //   linear-gradient(180deg, rgba(0, 0, 0, 0.09) 1px, transparent 1px);
  // background-size: 2.4px 2.4px;
  // animation: move-bg 0.10s infinite;
}

@keyframes move-bg {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 1px 1px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Vollkorn", serif;
  @include bg-pattern;
  display: flex;
  justify-content: center;

  .scroll-effect {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }
}

.scroll-child {
  scroll-snap-align: start;
  margin: 0 auto;
  max-width: 1380px;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: unset;
}


@keyframes pivot-in {
  0% {
    transform: rotateX(90deg) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg) scale(1);
    opacity: 1;
  }
}

.animated-letter {
  animation: pivot-in 0.5s forwards;
  opacity: 0;
}

@keyframes expand-from-middle {
  0% {
    width: 0;
    margin-left: 50%;
  }

  100% {
    width: 1140px;
  }
}

.black-line_block {
  display: flex;
  justify-content: center;

  .black-line {
    border: none;
    border-top: 2px solid black;
    width: 100%;
    margin-inline: 20px;
    animation: expand-from-middle 1s ease-out forwards;
  }
}

.expand-animation {
  animation: expand-from-middle 1s ease-out forwards;
}

.delayed-animation {
  animation-delay: 0.8s;
}