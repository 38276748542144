.conteneur-khalifa {
    display: flex;
    flex-direction: column;
    align-items: center;

    .poster {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: calc(100vh - 138px);
        margin: 0 auto;
        max-width: 1380px;
        position: relative;
        width: 100%;

        .date {
            position: absolute;
            top: 100px;
            left: 225px;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            font-size: 14px;
        }

        h3 {
            margin-top: -80px;
            font-size: 90px;
        }

        .poster-text {
            font-style: italic;
            margin-right: 20px;
        }

        .img-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            right: 300px;
            z-index: -1;
            gap: 50px;

            img {
                width: 500px;
                position: relative;
                height: 500px;
                object-fit: cover;
            }

            .under-img {
                font-size: 14px;
                font-weight: 700;
                width: 400px;
                text-align: center;
            }
        }

        .text {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            font-size: 50px;
        }
    }

    .showcase {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 100%;

        h3 {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            text-align: center;
            font-size: 200px;
            margin-right: -88px;
            height: 100%;
        }

        .img-block {
            height: 100vh;
            width: 75%;
            border-block: 5px solid #000;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .profil {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        height: 100vh;
        gap: 100px;

        .first-part {
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            padding-left: 150px;

            .work {
                display: flex;

                p {
                    font-size: 15px;
                    margin-top: 30px;
                }
            }

            h4 {
                margin-top: -55px;
            }

            h3,
            h4 {
                font-size: 90px;
            }


            .number {
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 50px;
                margin: 0 0 50px 50px;
                font-weight: 600;
            }

            @media only screen and (max-width: 1145px) {
                width: 40%;
            }
        }

        .second-part {
            display: flex;
            position: relative;
            gap: 100px;

            .block-text {
                width: 235px;
                margin-right: -75px;

                p {
                    font-size: 14px;
                    font-style: italic;
                    margin-top: 200px;
                    width: 270px;
                }
            }


            .block-background {
                background-color: #BCB9B0;
                height: 300px;
                width: 100%;
                z-index: -1;
                position: absolute;
                bottom: 200px;
            }

            .block-img {
                width: 100%;
                height: 100vh;
                z-index: -1;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

            }

            @media only screen and (max-width: 1145px) {
                width: 60%;

                .block-text {
                    p {
                        margin-top: 150px;
                    }
                }

                .block-img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 420px;
                }
            }
        }

        @media only screen and (max-width: $ms) {
            flex-direction: column;
            position: relative;
            gap: 0;

            .first-part {
                width: 100%;
                margin-top: 0;
                position: static;
                padding-left: 50px;

                .number {
                    left: auto;
                    right: 0;
                }
            }

            .second-part {
                width: 100%;
                height: 100%;

                .block-text {
                    margin-left: 50px;

                    p {
                        margin-top: 0;
                    }
                }
            }
        }

        @media only screen and (max-width: $ss) {
            .second-part {
                .block-background {
                    background: radial-gradient(black, transparent);
                    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
                    bottom: 250px;
                }

                .block-img {
                    width: 100%;
                }
            }
        }
    }

    .clip {
        margin-top: 200px;
        display: flex;
        align-items: center;
        height: 100vh;
        width: 100%;

        iframe {
            border-radius: 35px;
            border: none;
            height: 75vh;
            filter: grayscale(100%);
            width: 100%;
        }
    }

    .other-soundbox {
        margin-top: 200px;
        margin-bottom: 50px;

        img {
            height: 100vh;
            width: 100%;
            object-fit: contain;
        }
    }

}