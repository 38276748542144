.home-page {
  .hero {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 155px;

    p {
      font-size: 25px;
      font-weight: 700;

      @media only screen and (max-width: $ss ) {
        font-size: 15px;
      }
    }

    h1 {
      margin-bottom: 50px;
      font-size: 300px;

      @media only screen and (max-width: 1620px) {
        font-size: 250px;
      }

      @media only screen and (max-width: 1300px) {
        font-size: 180px;
        line-height: 50px;
      }

      @media only screen and (max-width: $ls ) {
        font-size: 120px;
        margin-top: -25px;
      }

      @media only screen and (max-width: $ms ) {
        font-size: 70px;
        margin-top: -55px;
      }

      @media only screen and (max-width: $ss ) {
        font-size: 55px;
        margin-top: -55px;
      }
    }
  }

  .poster {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-inline: 20px;

    .title_top,
    .title_bottom {
      flex-shrink: 0;
      height: 8vh;
      display: flex;

      p {
        font-size: 25px;
        font-weight: 700;
        // opacity: 0;
        // transform: translateY(20px);
        // transition: opacity 0.5s ease, transform 0.5s ease;

        @media only screen and (max-width: $ms ) {
          font-size: 20px;
        }

        @media only screen and (max-width: $ss ) {
          font-size: 12px;
        }
      }
    }

    .title_top {
      justify-content: space-between;
      align-items: center;
    }

    .images {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 25px;
      height: 84vh;

      img {
        object-fit: cover;
        border: 2.5px solid #000;
        height: 100%;
      }

      .profil {
        width: calc(60% - 12.5px);
      }

      .tree {
        width: calc(40% - 12.5px);
      }

      @media only screen and (max-width: $ms) {
        height: 100%;

        img {
          width: 100%;
          height: 850px;
        }
      }

      @media only screen and (max-width: $ss) {
        img {
          height: 550px;
        }
      }
    }

    .title_bottom {
      flex-direction: column;
      padding-bottom: 15px;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
    }

    @media only screen and (max-width: $ms) {
      height: 100%;
    }
  }

  .about {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block: 100px;
    gap: 15px;

    h2 {
      font-size: 80px;
      margin-left: 175px;
    }

    .about-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      &.second-img,
      &.third-img {
        img {
          width: 100%;
        }

        p {
          width: 450px;
        }
      }

      &.third-img {
        p {
          margin-bottom: 23px;
        }
      }

      &.first-img {

        .block-img {
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 100%;
          }
        }

        p {
          width: 600px;
          margin-bottom: 23px;
        }
      }

      .block-img {
        position: relative;
        height: 450px;

        img {
          object-fit: contain;
        }

        .pause-button {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          z-index: 10;
          top: 0;
          left: 0;
          cursor: pointer;

          button {
            opacity: 0;
            transform: scale(0.7);
            transition: opacity 0.3s ease, transform 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: none;
            background-color: rgba(0, 0, 0, .5);
            color: #DDD;
            cursor: pointer;
          }
        }

        &:hover button {
          opacity: 1;
          transform: scale(1);
        }
      }

      p {
        font-size: 16px;
        text-align: center;
      }
    }

    .carousel-dots {
      text-align: center;
      margin-bottom: 50px;

      .dot {
        height: 15px;
        width: 15px;
        margin: 0 5px;
        background-color: #DDD;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.active {
          background-color: #000;
        }
      }
    }

    @media only screen and (max-width: $ms) {
      height: auto;
      margin-block: 100px;
    }
  }

  .projects {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;

    h2 {
      font-size: 90px;
      display: flex;
      flex-direction: column;
      margin-left: 100px;
      padding-top: 20px;

      @media only screen and (max-width: $ms) {
        font-size: 60px;
        text-align: center;
        margin: 0;
      }

      @media only screen and (max-width: $ss ) {
        font-size: 50px;
      }
    }

    .card-project {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      margin-inline: 55px;

      .project-block {
        width: 350px;
        margin: 10px;

        .title-project {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            object-fit: contain;
            width: 100%;
          }

          h5 {
            color: black;
            font-size: 90px;
            font-weight: 780;
            margin-top: -65px;
          }
        }

        .text-card {
          width: 350px;


          p {
            font-style: italic;
            font-weight: 600;
            font-size: 16px;
          }

          @media only screen and (max-width: 1190px) {
            margin-top: -65px;

            h5 {
              font-size: 75px;
            }
          }
        }
      }

      @media only screen and (max-width: $ms ) {
        margin: 0 0 50px 0;

        .project-block {
          margin: 20px;

          .text-card {
            margin-top: -55px;

            h5 {
              font-size: 60px;
            }
          }
        }
      }

      @media only screen and (max-width: $ss ) {
        margin: 0 0 25px 0;

        .project-block {
          width: 100%;
          margin: 0;
          padding: 20px;

          .text-card {
            margin-top: -40px;
            width: 100%;

            h5 {
              font-size: 45px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 849px) {
      height: 100%;
    }
  }
}

@keyframes zoomPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes liftUp {
  0% {
    transform: rotateX(90deg);
    font-size: 280px;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    font-size: 280px;
    opacity: 1;
  }
}

// @keyframes myAnimation {
//   from {
//     transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
//       rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
//     transform-style: preserve-3d;
//   }
//   to {
//     transform: translate3d(100px, 100px, 100px) scale3d(1.5, 1.5, 1.5)
//       rotateX(360deg) rotateY(360deg) rotateZ(360deg) skew(20deg, 20deg);
//     transform-style: preserve-3d;
//   }
// }

@keyframes liftUpP {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// @keyframes grow {
//   from {
//     font-size: 280px;
//   }
//   to {
//     font-size: 300px;
//   }
// }